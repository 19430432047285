<template>
  <div>
    <van-loading type="spinner" color="#1989fa" class="loading" v-if="showLoading"/>
    <van-form class="block-form" @submit="onSubmit">
      <van-field
        v-model="formData.username"
        name="姓名"
        placeholder="姓名"
        maxlength="10"
        :rules="[{ required: true, message: '请填写姓名' }]"
      >
        <template #label>
          <p>姓名<span class="red">*</span></p>
        </template>
      </van-field>
      <van-field
        v-model="formData.phone"
        name="手机号"
        placeholder="手机号"
        maxlength="11"
        :rules="[{ required: true, message: '请填写手机号' }]"
      >
        <template #label>
          <p>手机号<span class="red">*</span></p>
        </template>
      </van-field>

      <van-field
        v-model="formData.company"
        name="单位"
        placeholder="单位"
        maxlength="30"
        :rules="[{ required: true, message: '请填写单位' }]"
      >
      <template #label>
          <p>单位<span class="red">*</span></p>
        </template>
     </van-field>
      <div style="margin: 16px;">
        <van-button class="full-btn" block type="info" native-type="submit">提交</van-button>
      </div>
    </van-form>
  </div>
</template>

<script>
import {Dialog} from "vant";

export default {
  data() {
    return {
      formData: {
        'id': 0,
        'phone': '',
        'username': '',
        'company': ''
      },
      showLoading: false
    };
  },
  created() {
    const id = this.$untils.getUrlKey('id');
    this.formData.id = id;
  },
  methods: {
    onSubmit() {
      let that = this
      if (that.showLoading == true) {
        return false
      }
      that.showLoading = true
      this.$api.CREATE_LIVE_POST(that.formData).then(res => {
        Dialog({ message: res.msg });
        this.$router.push({path: '/liveDetail', query: {id: this.formData.id}})
      })
    }
  }
};
</script>
<style lang="less" scoped>
  .loading {
    width: 50px;
    height: 60px;
    text-align: center;
    font-size: 10px;
    position:fixed;
    left:50%;
    top:50%;
    margin-top:-30px;
    margin-left:-25px;
  }
</style>